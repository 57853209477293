export default function replace(target, html){
  const tmp = document.createElement('div');
  tmp.innerHTML = html;

  let last = target;
  let i = tmp.childNodes.length;

  while(i--){
    let element = tmp.childNodes[i];
    target.parentNode.insertBefore(element, last);
    last = element;
  }
  /// remove the target.
  target.parentNode.removeChild(target);
}
