import { openModal, closeModal, isModalOpen } from '@script/blocks/modal';

const showProfileBtns = document.querySelectorAll('[data-show-profile]');
const hideProfileBtns = document.querySelectorAll('[data-hide-profile]');
const profileScrollBody = document.querySelector('.profile__scroll-body');

export default function() {
  const profile = document.querySelector('.profile');

  if(!profile){
    return;
  }

  const parentPageUrl = profile.getAttribute('data-parent-url');
  const profileContent = profile.querySelector('.profile__content');

  if(isModalOpen('profile')) {
    openModal('profile', onProfileClose);
  }

  for (const showProfileBtn of showProfileBtns) {
    showProfileBtn.addEventListener('click', function(e) {
      e.preventDefault();
      const url = showProfileBtn.href;

      loadPerson(url);
    });
  }

  for (const hideProfileBtn of hideProfileBtns) {
    hideProfileBtn.addEventListener('click', function(e) {
      e.preventDefault();
      //window.history.pushState();
      closeModal();
    });
  }

  function onProfileClose() {
    window.history.pushState({type: 'parent'}, '', parentPageUrl);
  }

  window.addEventListener('popstate', (event) => {
    if(event.state && event.state.type == 'person'){
      loadPerson(window.location.href);
    } else {
      closeModal();
    }
  })

  function loadPerson(url){
    const promise = fetch(url, {
      headers: {"Accept": "application/json"},
    });

    openModal('profile', onProfileClose);

    profileScrollBody.scrollTo(0, 0);

    window.history.pushState({type: 'person'}, '', url);

    promise.then((response) => {
      return response.json();
    }).then((data) => {
      console.log('data', data);

      profileContent.innerHTML = data.person;
    }).catch((reason)=>{
      alert('Ошибка при загрузке, попробуйте еще раз');
      console.error('Broken promise', reason);
    });

    return promise;
  }
}
