import ApplicationController from "@script/controllers/application_controller";

export default class TabsController extends ApplicationController {
  static targets = ['tab', 'tabContent'];

  connect(){
    super.connect();
  }

  select(e){
    e.preventDefault();
    const id = e.target.dataset.tabId;

    for (const tab of this.tabTargets) {
      const tabId = tab.dataset.tabId;
      tab.classList.toggle('active', id == tabId);
    }
    for (const tab of this.tabContentTargets) {
      const tabId = tab.dataset.tabId;
      tab.classList.toggle('hidden', id != tabId);
      tab.classList.toggle('active', id == tabId);
    }
  }
}
