import ApplicationController from "@script/controllers/application_controller";
import * as Swiper from "swiper/dist/js/swiper.js";

export default class GalleryController extends ApplicationController {
  static values = {count: String}

  connect(){
    super.connect();

    const fractionPagination = this.element.classList.contains('swiper-container--fraction');
    let pagination = null;

    if(fractionPagination) {
      pagination = {
        el: '.swiper-pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
          return `<span class="${currentClass}"></span>/<span class="${totalClass}"></span>`;
        }
      }
    } else {
      pagination = {
        el: '.swiper-pagination',
        clickable: true
      }
    }

    const swiper = new Swiper(this.element, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: pagination,
      slidesPerView: 'auto',
      spaceBetween: 34,
      init: false,
      breakpoints: {
        1023: {
          spaceBetween: 16
        },
        1365: {
          spaceBetween: 24
        }
      }
    });

    const slides = this.element.querySelectorAll('.swiper-slide');

    for (const slide of slides) {
      slide.addEventListener('click', () => swiper.slideTo(swiper.clickedIndex));
    }

    setTimeout(() => {
      swiper.init();
      swiper.updateSize();
    }, 100);
  }
}
