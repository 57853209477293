import ApplicationController from "@script/controllers/application_controller";

import scrollTo from '@script/utils/scroll-to';

export default class PeopleController extends ApplicationController {
  static targets = [
    'toggler', 'filterOption', 'filterItem', 'filterTitle',
  ];

  connect(){
    super.connect();

    this.updateGrid();
  }

  toggleFilter(e) {
    this.element.classList.toggle('people__filter-wrap--sorted');
  }

  chooseLetter(e){
    e.preventDefault();

    scrollTo(this.titleByLetter(e.target.dataset.letter));
  }

  titleByLetter(letter){
    return this.filterTitleTargets.find(t => t.dataset.letter == letter);
  }

  updateGrid() {
    for(const filterOption of this.filterOptionTargets){
      const letter = filterOption.dataset.letter;
      const order = parseInt(filterOption.dataset.order);

      const items = [...this.filterItemTargets]
            .filter(item => item.dataset.letter == letter)
            .sort((a, b) => a.dataset.name.localeCompare(b.dataset.name) );

      if(items.length == 0) {
        filterOption.classList.add('disabled');

        const title = this.titleByLetter(letter);
        if(title){
          title.remove();
        }
        continue;
      }

      for (const item of items) {
        order++;
        item.style.order = order;
      }
    }
  }
}
