const closeModalElements = document.querySelectorAll('[data-close-modal]');
const openModalElements = document.querySelectorAll('[data-open-modal]');
const overlay = document.querySelector('.modal__overlay');

let onModalClose = null;

export default function() {
  for (const element of openModalElements) {
    element.addEventListener('click', function(e) {
      const modalName = e.target.getAttribute('data-open-modal');

      if(modalName) {
        e.preventDefault();

        openModal(modalName);
      }
    });
  }

  for (const element of closeModalElements) {
    element.addEventListener('click', closeModal);
  }

  if(document.querySelector('.modal.modal--show')) {
    overlay.classList.add('modal__overlay--show');

    if(document.querySelector('.modal.modal--show.modal--profile')) {
      disableBodyScroll('profile');
    }
  }
}

function openModal(name, onclose) {
  const modal = document.querySelector(`.modal--${name}`);
  const oldActiveModal = document.querySelector('.modal--show');

  if(oldActiveModal) {
    oldActiveModal.classList.remove('modal--show');
    onModalClose && onModalClose();
  }

  if(modal) {
    modal.classList.add('modal--show');
    overlay.classList.add('modal__overlay--show');

    disableBodyScroll(name);
    onModalClose = onclose;
  } else {
    console.log(`modal "${name}" not defined, check selector .modal--${name}`);
  }
}

function isModalOpen(name) {
  const modal = document.querySelector(`.modal--${name}.modal--show`);

  return !!modal;
}

function closeModal() {
  const oldActiveModal = document.querySelector('.modal--show');

  oldActiveModal && oldActiveModal.classList.remove('modal--show');
  overlay.classList.remove('modal__overlay--show');

  enableBodyScroll();

  onModalClose && onModalClose();
}

function disableBodyScroll(modalName) {
  const body =  document.body;
  const bodyScrollInner = document.querySelector('.body__scroll-inner');

  if(!body.classList.contains('body--fixed')) {
    const offset = window.scrollY || window.pageYOffset || 0;
    const modal = document.querySelector(`.modal--${modalName}`);

    body.style.marginTop = `-${offset}px`;

    if(modalName == 'profile') {
      modal && (bodyScrollInner.style.height = `${offset + modal.clientHeight}px`);
      modal && (modal.style.top = `${offset}px`);
    }

    body.style.position = 'fixed';
    body.classList.add('body--fixed');
    modalName && body.setAttribute('data-modal-name', modalName);
    window.scrollTo(0, 0);
  }
}

function enableBodyScroll() {
  const body =  document.body;
  const bodyScrollInner = document.querySelector('.body__scroll-inner');
  const scrollY = body.style.marginTop;

  body.style.position = '';
  body.style.marginTop = '';
  bodyScrollInner.style.height = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  body.classList.remove('body--fixed');
  body.removeAttribute('data-modal-name');
}

export { openModal, closeModal, isModalOpen, disableBodyScroll, enableBodyScroll};
