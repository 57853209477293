import ApplicationController from "@script/controllers/application_controller";
import ajax from '@script/utils/ajax';
import replace from '@script/utils/replace';

export default class TicketsController extends ApplicationController {
  static targets = ['termsModal'];

  connect(){
    super.connect();
  }

  showTerms(e){
    e.preventDefault();
    this.termsModalTarget.modalController.open();
  }
}
