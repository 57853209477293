import ApplicationController from "@script/controllers/application_controller";
import Inputmask from "inputmask";


export default class ModalController extends ApplicationController {
  static targets = ['content'];

  static activeModal = null;

  connect(){
    super.connect();
    this.overlay = document.querySelector('.modal__overlay');
    Inputmask().mask(this.element.querySelectorAll("input"));
  }

  open(){
    const activeModal = ModalController.activeModal;
    if(activeModal && activeModal.element){
      activeModal.close();
    }
    ModalController.activeModal = this;

    this.element.classList.toggle('modal--show', true);
    this.overlay.classList.toggle('modal__overlay--show', true);

    disableBodyScroll();
    //this.overlayClick = this.overlay.addEventListener('click', () => this.close());
  }

  toggleLoader(state){
    this.element.classList.toggle('modal--loading', state);
  }

  close(){
    this.element.classList.toggle('modal--show', false);
    this.overlay.classList.toggle('modal__overlay--show', false);

    this.activeModal = null;

    enableBodyScroll();
    this.dispatch('modal:close');
    //this.overlay.removeEventListener('click', this.overlayClick);
  }

  changeContent(html){
    this.contentTarget.innerHTML = html;
    setTimeout(() => Inputmask().mask(this.element.querySelectorAll("input")), 0);
  }
}

function disableBodyScroll() {
  if(!document.body.classList.contains('body--fixed')) {
    document.body.style.top = `-${window.scrollY || window.pageYOffset || 0}px`;
    document.body.style.position = 'fixed';
    document.body.classList.add('body--fixed');
  }
}

function enableBodyScroll() {
  const scrollY = document.body.style.top;

  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
  document.body.classList.remove('body--fixed');
}
