import { disableBodyScroll, enableBodyScroll } from '@script/blocks/modal';

const openSearchBtns = document.querySelectorAll('[data-open-search]');
const closeSearchBtns = document.querySelectorAll('[data-close-search]');
const searchInput = document.querySelector('input.search__input');
const searchClear = document.querySelector('.search__clear');
const header = document.querySelector('.header');

export default function() {
  for (const openSearchBtn of openSearchBtns) {
    openSearchBtn.addEventListener('click', function(e) {
      e.preventDefault();

      document.body.classList.add('body--search');
      disableBodyScroll();

      searchInput && setTimeout(() => searchInput.focus(), 100);
      header && header.classList.remove('header--show');
    });
  }

  for (const closeSearchBtn of closeSearchBtns) {
    closeSearchBtn.addEventListener('click', function(e) {
      e.preventDefault();

      document.body.classList.remove('body--search');
      enableBodyScroll();
    });
  }

  searchClear && searchClear.addEventListener('click', function(e) {
    e.preventDefault();

    if (searchInput) {
      searchInput.value = '';

      setTimeout(() => searchInput.focus(), 100);
    }
  });

  searchInput && searchInput.addEventListener('keyup', (e) => {
    e.code == 'Enter' && searchInput.blur();
  });
}
