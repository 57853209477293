const openMapElements = document.querySelectorAll('[data-open-map]');
const closeMapElements = document.querySelectorAll('[data-close-map]');

export default function() {
  for (const openMapElement of openMapElements) {
    openMapElement.addEventListener('click', (e) => {
      e.preventDefault();

      const id = openMapElement.getAttribute('data-open-map');
      const map = document.querySelector(`[data-map-id="${id}"]`);

      map && map.classList.add('show');
    });
  }

  for (const closeMapElement of closeMapElements) {
    closeMapElement.addEventListener('click', (e) => {
      e.preventDefault();

      for (const map of document.querySelectorAll(`[data-map-id]`)) {
        map.classList.remove('show');
      }
    });
  }
}
