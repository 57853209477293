import ApplicationController from "@script/controllers/application_controller";
import replace from '@script/utils/replace';
import ajax from '@script/utils/ajax';

export default class CartController extends ApplicationController {
  static values = {count: String, dateId: Number}
  static targets = ['promocodeForm', 'promocodeInput', 'promocodeBtn', 'msgInfo', 'msgError']

  connect(){
    this.countValueChanged();
    this.dispatch('tickets:cart_connect');
  }

  countValueChanged(){
    this.element.classList.toggle('full', this.countValue > 0);
  }

  applyPromocode(e){
    e.preventDefault();

    const code = this.promocodeInputTarget.value;
    const action = this.promocodeFormTarget.action;

    console.log('apply promocode', e, code);

    this.clearMessages();
    this.setFormState(false);

    this.dispatch('tickets:add_to_queue', () =>
      ajax.post(action, {code: code, date_id: this.dateIdValue})
        .then(response => response.json())
        .then(data => {
          if(data.status && data.status == 500) {
            this.setFormState(true);
            this.setErrorText('Произошла ошибка сервера, попробуйте еще раз');
            console.error('Error:', data);
          } else {
            console.log('Success:', data);
            this.setFormState(true);
            replace(this.element, data.cart);
          }
        })
        .catch((error) => {
          this.setFormState(true);
          this.setErrorText('Произошла ошибка сервера, попробуйте еще раз');
          console.error('Error:', error);
        })
    );
  }

  clearMessages(){
    const {msgInfoTarget, msgErrorTarget} = this;

    [msgInfoTarget, msgErrorTarget].forEach(msg => {
      msg.classList.add('hidden');
      msg.innerHTML = '';
    });
  }

  setErrorText(text){
    const show = text && text.length;
    this.msgErrorTarget.classList.toggle('hidden', !show);
    this.msgErrorTarget.innerHTML = show ? text : '';
  }

  setFormState(enabled){
    this.promocodeBtnTarget.disabled = !enabled;
    this.promocodeInputTarget.disabled = !enabled;

    this.dispatch('tickets:set_scheme_locked_state', enabled);
  }
}
