import ApplicationController from "@script/controllers/application_controller";

import ajax from '@script/utils/ajax';

export default class OrderController extends ApplicationController {
  static values = {};
  static targets = ['form'];

  connect(){
    super.connect();
  }

  back(e){
    this.submit(e, true);
  }

  submit(e, back = false){
    e.preventDefault();

    const dateId = this.formTarget.dataset.dateId;

    const formData = new FormData(this.formTarget);

    if(dateId){
      formData.append('date_id', dateId);
    }
    if(back){
      formData.append('back', 1);
    }

    this.element.modalController.toggleLoader(true);

    ajax.post(this.formTarget.action, formData)
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
        this.element.modalController.changeContent(data.html);
        if(data.redirect_url){
          window.location.href = data.redirect_url;
        }
        if(data.reload){
          window.location.reload();
        }
        if(data.error){
          console.log('error');
          this.dispatch('tickets:reload_cart');
        }
      })
      .catch((error) => {
        this.dispatch('tickets:reload_cart');
        console.error('Error:', error);
        alert('Произошла ошибка');
      })
      .finally(() => {
        this.element.modalController.toggleLoader(false);
      });

  }
}
