const posterItems = document.querySelectorAll('.poster-item');
const posterSubscribe = document.querySelector('.poster-subscribe');

export default function() {
  for (const posterItem of posterItems) {
    const title = posterItem.querySelector('.poster-item__title');
    const img = posterItem.querySelector('.poster-item__img');

    title.addEventListener('mouseover', () => img.classList.add('poster-item__img--hover'), {passive: true});
    title.addEventListener('mouseout', () => img.classList.remove('poster-item__img--hover'), {passive: true});
  }
}
