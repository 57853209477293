import SubscribeController from "@script/controllers/subscribe_controller";

export default class SubscribeTicketController extends SubscribeController {
  static values = {};
  static targets = ['form', 'result', 'error', 'email'];

  connect(){
    super.connect();

    this.openHandler = (e) => {
      if(e.target.dataset.subscribeObjectType){
        this.open();
      }
    }

    document.addEventListener('click', this.openHandler);

    this.emailTarget.addEventListener('input', () => {
      this.errorTarget.textContent = '';
    })
  }

  disconnect(){
    super.disconnect();

    window.removeEventListener('click', this.openHandler);
  }

  open() {
    this.element.classList.remove('hidden');
  }

  close() {
    this.element.classList.add('hidden');
    this.reset(true);
  }

}
