import ApplicationController from "@script/controllers/application_controller";

import ajax from '@script/utils/ajax';

export default class SubscribeController extends ApplicationController {
  static values = {};
  static targets = ['form', 'result', 'error', 'email'];

  connect() {
    console.log('connect');
    super.connect();

    this.emailTarget.addEventListener('input', () => {
      this.errorTarget.textContent = '';
    })
  }

  submit(e) {
    e.preventDefault();

    if (this.isValid() &&
    (av && av.sfTouched === true) // см. avilkin.js
    ) {
      const dateId = this.formTarget.dataset.dateId;

      const formData = new FormData(this.formTarget);

      formData.append('id', dateId);

      ajax.post(this.formTarget.action, formData)
        .then(response => response.json())
        .then(data => {
          this.resultTarget.innerHTML = data.html;
          this.resultTarget.classList.remove('hidden');
          this.formTarget.classList.add('hidden');
        }).catch((error) => {
          console.error('Error:', error);
          this.errorTarget.textContent = error || 'Произошла ошибка';
        });
    }
  }

  reset(resetDateId = false) {
    this.formTarget.reset();

    this.resultTarget.classList.add('hidden');
    this.formTarget.classList.remove('hidden');

    this.resultTarget.innerHTML = '';
    this.errorTarget.textContent = '';

    resetDateId && this.formTarget.removeAttribute('data-date-id');
  }

  isValid() {
    const isEmailEmpty = !this.emailTarget.value.length;
    const isEmailInvalid = !this.emailTarget.checkValidity();

    if (isEmailEmpty) {
      this.errorTarget.textContent = this.errorTarget.dataset.msgEmpty;
    } else if (isEmailInvalid) {
      this.errorTarget.textContent = this.errorTarget.dataset.msgInvalid;
    }

    return !isEmailEmpty && !isEmailInvalid;
  }
}
