import ApplicationController from "@script/controllers/application_controller";
import svgPanZoom from "svg-pan-zoom";
import event from '@script/utils/event';
import Hammer from 'hammerjs';

export default class SchemeController extends ApplicationController {
  static targets = [
    'object',
    'scheme',
    'viewport',
    'wrapper',
    'alignmentBoxLayer',
  ];

  static values = {
    selectedObjects: Array,
    limit: Number,
    cartCount: Number,
    lastWindowWidth: Number
  }

  connect(){
    super.connect();

    const eventsHandler = {
      haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
      init: (options) => {
        const instance = options.instance;
        let initialScale = 1;
        let pannedX = 0;
        let pannedY = 0;

        // Init Hammer
        // Listen only for pointer and touch events
        this.hammer = Hammer(options.svgElement, {
        //  inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
          inputClass: Hammer.TouchInput
        });

        this.hammer.get('pinch').set({enable: false});
        this.hammer.get('pan').set({enabled: false, direction: Hammer.DIRECTION_NONE});

        instance.setOnZoom((scale) => {
          if(scale > 1.01) {
            this.hammer.get('pan').set({enabled: true, direction: Hammer.DIRECTION_ALL});
          } else {
            this.hammer.get('pan').set({enabled: false, direction: Hammer.DIRECTION_NONE});
          }
        })

        options.svgElement.addEventListener('touchstart', (ev) => {
          this.hammer.get('pinch').set({enable: ev.touches.length > 1});
        });

        options.svgElement.addEventListener('touchend', () => {
          this.hammer.get('pinch').set({enable: false});
        });

        // Handle double tap
        this.hammer.on('doubletap', () => {
          instance.zoomIn();
        })

        this.hammer.on('tap', (ev) => {
          if(ev.target.tagName == "use"){
            this.objectClick(ev);
            ev.srcEvent.stopPropagation();
          }
        })

        // Handle pan
        this.hammer.on('panstart panmove', (ev) => {
          const instancePan = instance.getPan();
          const sizes = instance.getSizes();
          const topLimit = -(((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) - sizes.height);

          // On pan start reset panned variables
          if (ev.type === 'panstart') {
            pannedX = 0;
            pannedY = 0;
          }

          const overscrollTop = instancePan.y == 0 && ev.deltaY - pannedY > 0;
          const overscrollBottom = instancePan.y == topLimit && ev.deltaY - pannedY < 0;

          if(overscrollTop || overscrollBottom) {
            window.scrollTo(0, window.scrollY - (ev.deltaY - pannedY));
          }

          instance.panBy({x: ev.deltaX - pannedX, y: ev.deltaY - pannedY});

          pannedX = ev.deltaX;
          pannedY = ev.deltaY;
        });

        // Handle pinch
        this.hammer.on('pinchstart pinchmove', (ev) => {
          // On pinch start remember initial zoom
          if (ev.type === 'pinchstart') {
            initialScale = instance.getZoom()
            instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
          }

          instance.zoomAtPoint(initialScale * ev.scale, {x: ev.center.x, y: ev.center.y})
        })
      },
      destroy: () => {
        this.hammer.destroy()
      }
    };

    this.schemeTarget.style.maxWidth = `${window.innerWidth}px`;

    this.panzoom = svgPanZoom(this.schemeTarget, {
      viewportSelector: '.svg-pan-zoom_viewport',
      center: true,
      fit: true,
      contain: true,
      zoomEnabled: true,
      zoomScaleSensitivity: 0.10,
      dblClickZoomEnabled: false,
      mouseWheelZoomEnabled: false,
      minZoom: 1,
      maxZoom: 8,
      customEventsHandler: eventsHandler,
      panEnabled: false,

      beforePan: function(oldPan, newPan){
        const sizes = this.getSizes();
        //const gutterWidth = 0;
        //const gutterHeight = 0;
        // console.log(sizes, newPan);

        const leftLimit = -(((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) - sizes.width);
        const rightLimit = sizes.width - (sizes.viewBox.x * sizes.realZoom) - sizes.width;
        const topLimit = -(((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) - sizes.height);
        const bottomLimit = sizes.height - (sizes.viewBox.y * sizes.realZoom) - sizes.height;

        return {
          x: Math.max(leftLimit, Math.min(rightLimit, newPan.x)),
          y: Math.max(topLimit, Math.min(bottomLimit, newPan.y)),
        };
      }
    });

    const isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0))
    if(!isTouch){
      this.objectTargets.forEach((obj) => {
        obj.addEventListener('click', (e) => this.objectClick(e));
      });
    }

    window.addEventListener('resize', () => {
      this.schemeTarget.style.maxWidth = `${window.innerWidth}px`;

      if(this.lastWindowWidthValue != window.innerWidth) {
        this.panzoom.resize();
        this.panzoom.fit();
        this.panzoom.center();
      }

      this.lastWindowWidthValue = window.innerWidth;
    });
  }

  disconnect(){
    this.panzoom.destroy();
  }

  zoomIn(e){
    e.preventDefault();
    this.panzoom.zoomIn();
  }

  zoomOut(e){
    e.preventDefault()
    this.panzoom.zoomOut();
  }

  updateSeatsAvailibility(seats){
    for(const id in seats){
      const availible = seats[id];
      const element = this.objectTargets.find((e) => parseInt(e.dataset.objectId) == id);

      if(!element){
        continue;
      }

      const selected = element.dataset.selected == 'true';

      element.dataset.availible = availible ? '1' : '0';
      this.toggleSeatElement(id, selected);
    }

  }

  setSeats(ids){
    this.selectedIds().forEach((id)=>{
      this.toggleSeatElement(id, false);
    });
    ids.forEach((id)=>{
      this.toggleSeatElement(id, true, 'seatId');
    });
  }

  addSeat(id){
    this.toggleSeatElement(id, true);
  }

  removeSeat(id){
    this.toggleSeatElement(id, false);
  }

  selectedIds(){
    return this.objectTargets.filter((e) => e.dataset.selected == 'true').map((e) => e.dataset.objectId);
  }


  toggleSeatElement(id, selected, property = 'objectId'){
    id = parseInt(id);
    const element = this.objectTargets.find((e) => parseInt(e.dataset[property]) == id)

    if(!element){
      return;
    }

    const index = element.dataset.priceIndex;
    let href = selected ? `#seat-icon-${index}-selected` : `#seat-icon-${index}`;

    if(element.dataset.availible == '0'){
      href = '#seat-icon-occupied';
    }

    element.querySelector('use').setAttribute('href', href);
    element.dataset.selected = selected ? 'true' : 'false';
  }

  objectClick(e){
    const element = e.target.parentElement;
    const object = JSON.parse(element.dataset.object);
    const objectId = element.dataset.objectId;

    if(object.type != 'seat'){
      return;
    }

    const selected = element.dataset.selected != 'true';

    if(selected && (this.cartCountValue + 1) > this.limitValue){
      return;
    }

    const unavailible = element.dataset.availible != '1';

    if(unavailible) {
      return;
    }

    if(selected && object.price != 0){
      this.addSeat(objectId);
      this.dispatch('tickets:add_to_cart', object);
    } else {
      this.removeSeat(objectId);
      this.dispatch('tickets:remove_from_cart', object);
    }
  }

  updateHighlight(priceIndex){
    for (const object of this.objectTargets) {
      if(priceIndex === null) {
        object.classList.toggle('price-highlight-hidden', false);
      } else {
        object.classList.toggle('price-highlight-hidden', object.dataset.priceIndex != priceIndex);
      }
    }
  }
}
