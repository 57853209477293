import { throttle } from "lodash";

const menuTogger = document.querySelector('.header__menu-toggle');
const menuOverlay = document.querySelector('.header__overlay');
const header = document.querySelector('.header');
const fixedWrap = document.querySelector('.header__fixed-wrap');
const filter = document.querySelector('.filter');
const submenu = document.querySelector('.header__submenu');
const submenuLinks = document.querySelectorAll('.header__submenu .menu__link');
const body = document.querySelector('body');
const monthNames = document.querySelectorAll('.poster__month');

let lastScrollPosition = 0;
let sleepzone = 0;

export default function() {
  setActiveElement();

  window.addEventListener('load', function() {
    resizeHandler();

    header && window.addEventListener('resize', resizeHandler);
    header && window.addEventListener('scroll', throttle(updateHeaderPosition, 128), {passive: true});

    menuTogger && menuTogger.addEventListener('click', function() {
      header.classList.toggle('header--show');
    });

    menuOverlay && menuOverlay.addEventListener('click', function() {
      header.classList.toggle('header--show');
    });

    for (const submenuLink of submenuLinks) {
      submenuLink.addEventListener('click', (e) => {
        if(this.window.innerWidth < 1180) {
          if(submenuLink.classList.contains('active')) {
            e.preventDefault();

            submenu.classList.toggle('open');
          }
        }
      });
    }
  });
}

function resizeHandler() {
  sleepzone = fixedWrap.clientHeight * 2;

  setTimeout(updateHeaderPosition, 100);
}

function updateMonthNamesPostion(){
  let firstTop = null;
  for (const monthName of monthNames) {
    const top = monthName.offsetTop-window.scrollY;
    firstTop = firstTop || top;
    if (top == firstTop) {
      monthName.classList.add('state-top');
    } else {
      monthName.classList.remove('state-top');
    }
  }

}

function updateHeaderPosition() {
  requestAnimationFrame(() => {
    const newScrollPosition = window.scrollY;
    const isDirectionDown = lastScrollPosition < newScrollPosition;

    if(newScrollPosition < sleepzone) {
      header.classList.remove('header--down');
      header.classList.remove('header--up');
      header.classList.add('header--top');

      body.classList.remove('body--header-down');
      body.classList.remove('body--header-up');
      body.classList.add('body--header-top');

      filter && filter.classList.remove('filter--down');
    } else if (isDirectionDown && newScrollPosition) {
      header.classList.add('header--up');
      header.classList.remove('header--down');
      header.classList.remove('header--top');

      body.classList.add('body--header-up');
      body.classList.remove('body--header-down');
      body.classList.remove('body--header-top');

      filter && filter.classList.remove('filter--down');
    } else {
      header.classList.add('header--down');
      header.classList.remove('header--up');
      header.classList.remove('header--top');

      body.classList.add('body--header-down');
      body.classList.remove('body--header-up');
      body.classList.remove('body--header-top');

      filter && filter.classList.add('filter--down');
    }
    updateMonthNamesPostion();

    lastScrollPosition = newScrollPosition;
  });
}

function setActiveElement() {
  const noActiveGroups = document.querySelectorAll('.menu__list.active.no-active-item');

  for (const noActiveGroup of noActiveGroups) {
    let active;
    let link;
    let item;

    for (const menuItem of noActiveGroup.querySelectorAll('.menu__item')) {
      const allLink = menuItem.querySelector('.menu__link[href*="/all"]');

      if(allLink) {
        active = allLink;
        item = menuItem;
        link = allLink;

        break;
      }
    }

    if(!active) {
      item = noActiveGroup.querySelector('.menu__item');
      link = item.querySelector('.menu__link');
    }

    link && link.classList.add('active');
    item && item.classList.add('active');
  }
}
