import { throttle } from "lodash";
import scrollTo from '@script/utils/scroll-to';

export default function(){
  const container = document.querySelector('.news-articles');

  let intervalList = [];

  if(!container){
    return;
  }

  window.addEventListener('load', function() {
    scrollTo(0, 100);
    loadNext();
    updateUrlIntervalList();

    setTimeout(() => scrollTo(0, 100), 100);

    window.addEventListener('scroll', throttle(checkScrollPosition, 500), {passive: true});
    window.addEventListener('resize', updateUrlIntervalList);
  });

  function checkScrollPosition() {
    let url = '';

    intervalList[intervalList.length-1].positionLoadNext < window.pageYOffset && loadNext();

    for (const interval of intervalList) {
      url = interval.path;

      if(interval.positionUrl > window.pageYOffset) {
        break;
      }
    }

    updateUrl(url);
  }

  function updateUrl(url) {
    if(location.pathname != url) {
      history.replaceState({}, null, url);
    }
  }

  function updateUrlIntervalList() {
    const articles = document.querySelectorAll('.news-article');

    intervalList = [];

    for (const article of articles) {
      const path = article.getAttribute('data-path');
      const { top, bottom } = article.getBoundingClientRect();

      if(intervalList.length) {
        intervalList[intervalList.length-1].positionUrl = top + window.pageYOffset;
      }

      intervalList.push({
        path,
        positionUrl: bottom + window.pageYOffset,
        positionLoadNext: bottom + window.pageYOffset - window.innerHeight
      });
    }
  }

  function loadNext(){
    const lastArticle = getLastArticle();

    loadNextArticle(lastArticle);
  }

  function loadNextArticle(article){
    const nextUrl = article.dataset.next;

    if(!nextUrl){
      return;
    }

    const promise = fetch(nextUrl, {
      headers: {"Accept": "application/json"},
    });

    promise.then((response) => {
      return response.json();
    }).then((data) => {
      const html = data.article;
      const holder = document.createElement('DIV');

      holder.innerHTML = html;

      const next = holder.firstChild;

      // Awoding usage of .after();
      const ref = article.nextSibling;
      if( ref ){
        article.parentNode.insertBefore(next, ref);
      } else {
        article.parentNode.appendChild(next);
      }

      updateUrlIntervalList();
    }).catch((e) => {
      console.error(e);
    });
  }


  function getLastArticle(){
    const articles = document.querySelectorAll('.news-article');

    return articles[articles.length-1];
  }
}
