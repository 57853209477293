const modeBtnList = document.querySelectorAll('[data-poster-mode]');
const posterPage = document.querySelector('.poster');

export default function() {
  if(posterPage) {
    for (const modeBtn of modeBtnList) {
      modeBtn.addEventListener('click', function(e) {
        const mode = modeBtn.getAttribute('data-poster-mode');

        e.preventDefault();

        posterPage.setAttribute('data-mode', mode);
      });
    }
  }
}
