import ApplicationController from "@script/controllers/application_controller";

import ajax from '@script/utils/ajax';

export default class OrderItemController extends ApplicationController {
  static targets = ['nameInput'];

  connect(){
    super.connect();
  }

  isCustomerChange(e){
    const target = e.target;

    if(target.checked){
      this.nameInputTarget.value = e.target.dataset.customerName;
    } else {
      this.nameInputTarget.value = '';
    }

  }
}
