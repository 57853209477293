import ApplicationController from "@script/controllers/application_controller";

export default class RolesController extends ApplicationController {
  static targets = ['showAll'];

  connect(){
    super.connect();
  }

  show(e){
    e.preventDefault()

    const hiddenItems = this.element.querySelectorAll('.role-list__item.hidden');

    this.showAllTarget.classList.add('hidden');

    for (const hiddenItem of hiddenItems) {
      hiddenItem.classList.remove('hidden');
    }
  }
}
