const seats = document.querySelectorAll('.ticket-seat');
const tip = document.querySelector('.ticket__tip');
const textSeat = tip && tip.querySelector('.ticket__tip-seat');
const textPrice = tip && tip.querySelector('.ticket__tip-price');

let isTouchMode = false;

export default function() {
  if(tip) {
    window.addEventListener('touchstart', () => isTouchMode = true, {once: true});
    window.addEventListener('scroll', leaveHandler, {passive: true});
    window.addEventListener('resize', leaveHandler, {passive: true});

    for (const seat of seats) {
      seat.addEventListener('mouseenter', enterHandler);
      seat.addEventListener('mouseleave', leaveHandler);
    }
  }
}

function enterHandler(e) {
  const data = JSON.parse(e.target.dataset.object);
  const {sector, row, seat, price} = data;

  textSeat.innerHTML = `${sector} <br/>${row} РЯД, ${seat} МЕСТО`;

  if(price > 0) {
    textPrice.innerHTML = `${price}₽`;
  } else {
    textPrice.innerHTML = ``;
  }

  if(isTouchMode) {
    tip.classList.toggle('show', e.target.getAttribute('data-selected') === 'true');
  } else {
    tip.classList.add('show');
  }

  setTipPosition(e.clientX, e.clientY);
}

function leaveHandler() {
  tip.classList.remove('show');
}

function setTipPosition(x, y) {
  const sidePadding = 15;
  const tipWidth = tip.clientWidth/2;
  let posX = x || 0;
  let posY = y || 0;

  if(x < tipWidth - sidePadding) {
    posX = sidePadding + tipWidth;
  }

  if(x > window.innerWidth - tipWidth - sidePadding) {
    posX = window.innerWidth - sidePadding - tipWidth;
  }

  tip.style.top = `${posY}px`;
  tip.style.left = `${posX}px`;
}
