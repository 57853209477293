import ApplicationController from "@script/controllers/application_controller";
import ajax from '@script/utils/ajax';

export default class SearchController extends ApplicationController {
  static targets = [
    'input',
    'results',
    'noResults',
  ];

  connect(){
    super.connect();
  }

  search(event){
    const query = this.query;

    if(query.length == 0){
      this.resultsTarget.innerHTML = '';

      this.toggleResults(true);
      return
    }

    ajax.post('/search', {query})
      .then(response => response.json())
      .then(data => {
        if(query != this.query){
          return;
        }

        if(data.count > 0){
          this.resultsTarget.innerHTML = data.html;

          this.toggleResults(true);
        } else {
          this.toggleResults(false);
        }
      });

  }

  get query(){
    return this.inputTarget.value.trim();
  }

  toggleResults(state){
    this.resultsTarget.classList.toggle('hidden', !state);
    this.noResultsTarget.classList.toggle('hidden', state);

  }
}
