import scrollTo from '@script/utils/scroll-to';

const scrolltoBtns = document.querySelectorAll('[data-scrollto]');

export default function() {
  for (const scrolltoBtn of scrolltoBtns) {
    scrolltoBtn.addEventListener('click', function(e) {
      const targetId = scrolltoBtn.getAttribute('data-scrollto');
      const elementById = document.getElementById(targetId);
      const elementByDataId = document.querySelector(`[date-scrollto-id="${targetId}"]`);
      const element = elementById || elementByDataId;

      if(element) {
        e.preventDefault();
        scrollTo(element);
      }
    });
  }

  scrollToHash();
}

function scrollToHash() {
  const hash = window.location.hash && window.location.hash.replace('#', '');
  hash && window.addEventListener('load', () => {
    setTimeout(() => {
      const scrollTarget = document.getElementById(hash);
      scrollTarget && scrollTo(scrollTarget);
    }, 300);
  })
}
