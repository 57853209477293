import * as Swiper from "swiper/dist/js/swiper.js";

const swiperContainer = document.querySelector('.main-event .swiper-container');
const swiperWrapper = swiperContainer && swiperContainer.querySelector('.swiper-wrapper')

export default function() {
  if(swiperContainer) {
    const mainEventSwiper = new Swiper(swiperContainer, {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      effect: 'fade',
      cssMode: true,
      slidesPerView: 1,
      speed: 1000,
      autoHeight: true,
      autoplay: {
        delay: 7000
      },
      loop:true,
      init: false
    });

    mainEventSwiper.on('snapIndexChange', function() {
      const speed = mainEventSwiper.params.speed;

      swiperWrapper.classList.add('swiper-wrapper--move');
      setTimeout(() => swiperWrapper.classList.remove('swiper-wrapper--move'), speed/2)
    });

    window.addEventListener('load', function() {
      mainEventSwiper.init();
      mainEventSwiper.updateSize();
    });
  }
}

