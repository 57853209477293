import cookie from 'cookie';

const department = document.querySelector('.department');
const closeBtn = department && department.querySelector('.department__close');

export default function() {
  if(department && closeBtn) {
    const pageCookie = document.cookie || '';
    const oldValue = Number((cookie.parse(pageCookie)['department']) || 0);

    if(oldValue < 3) {
      const header = document.querySelector('.header');

      header && header.classList.add('header--with-department');

      closeBtn.addEventListener('click', () => {
        header && header.classList.remove('header--with-department');

        document.cookie = cookie.serialize('department', String(oldValue+1), {
          maxAge: 60 * 60 * 24 * 365
        });
      });
    }
  }
}
