document.addEventListener('DOMContentLoaded', () => {
  function fetchScript(url) {
    let fetched = false;
    return new Promise((resolve, reject) => {
      if(fetched){
        resolve();
      }

      script = document.createElement('script');

      script.type = 'text/javascript';
      script.onload = resolve;
      script.onerror = reject;
      script.src = url;
      script.async = 'async';

      document.head.appendChild(this.script);
    }).finally(() => { fetched = true; } );
  }

  const containers = document.querySelectorAll('.map-holder');

  if(containers.length == 0){
    return;
  }

  fetchScript('https://api-maps.yandex.ru/2.1/?apikey=test&lang=ru_RU').then(() => {
    ymaps.ready(() => {
      for(let container of containers){
        initMaps(container.id, container.dataset);
      }
    });
  });
});

function initMaps(id, data){
  let placemark = null;
  let coords = null;

  if(data && data.lat && data.lng){
      coords = [data.lat, data.lng];
  }

  const map = new ymaps.Map(id,{
    center: coords || [55.751574, 37.573856],
    zoom: 15,
    //controls: ['smallMapDefaultSet'],
  }, {
  //  searchControlProvider: 'yandex#search'
  });

  if(coords){
    addPlacemark(coords);
  }


  function addPlacemark(coords){
    placemark = new ymaps.Placemark(coords);
    map.geoObjects.add(placemark);
  }
}
