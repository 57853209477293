import animateScrollTo from 'animated-scroll-to';
const headerContainer = document.querySelector('.header__container');
const filterContainer = document.querySelector('.filter__container');
const isHeaderFixed = !!document.querySelector('.page--header-fixed')

export default function(element, speed, scrollContainer) {
  if(typeof(element) == "string"){
    element = document.querySelector(element);
  }

  if(typeof(scrollContainer) == "string"){
    scrollContainer = document.querySelector(scrollContainer);
  }

  const elementToScroll = scrollContainer || window;
  const scrollContainerTopOffset = scrollContainer ?
    offset(elementToScroll).top - elementToScroll.scrollTop : 0;
  const yPosition = (typeof(element) == "number") ? element : offset(element).top;
  const yTarget = yPosition - scrollContainerTopOffset;

  try {
    pluginScroll(yTarget, speed || 1000, elementToScroll);
  } catch {
    defaultScroll(yTarget, elementToScroll);
  }
}

function pluginScroll(yTarget, speed, elementToScroll) {
  animateScrollTo(yTarget, {
    speed,
    elementToScroll
  });
}

function defaultScroll(yTarget, elementToScroll) {
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

  if(supportsNativeSmoothScroll) {
    elementToScroll.scrollTo({
      top: yTarget,
      behavior: "smooth"
    });
  } else {
    elementToScroll.scrollTo(0, yTarget);
  }
}

export function offset(el) {
  const rect = el.getBoundingClientRect();
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const headerOffset = headerContainer && isHeaderFixed ? headerContainer.clientHeight : 0;
  const filterOffset = filterContainer ? filterContainer.clientHeight : 0;
  const top = rect.top + scrollTop - headerOffset - filterOffset;
  const left = rect.left + scrollLeft;

  return { top, left }
}
