import flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js"

import { checkForVisibility } from '@script/blocks/onscroll-animation';

const filterGroups = document.querySelectorAll('.filter__group');
const form = document.querySelector('.filter form');
const mobileToggler = document.querySelector('.filter__mobile-toggle');

const filterOptionsContainer = document.querySelector('.people__filter-options');
const filterOptionsElements = document.querySelectorAll('.people__filter-option');
const filterTitleElements = document.querySelectorAll('.people__filter-title');

let filterParams = {}

export default function() {
  flatpickr("#filter-calendar", {
    locale: Russian,
    inline: true,
    dateFormat: 'd.m.Y',
    prevArrow: '',
    nextArrow: '',
    monthSelectorType: 'static',
    defaultDate: null,
    disable: [
      function(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${day}.${month}.${year}`;
        const items = document.querySelectorAll(`[data-filter-date="${formattedDate}"]`);

        return !items || items.length == 0;
      }
    ],
  });

  for (const filterGroup of filterGroups) {
    const toggleBtns = filterGroup.querySelectorAll('.filter__group-toggle, .filter__drop-menu-close-overlay');
    const openClass = 'filter__group--open';


    for (const toggleBtn of toggleBtns) {
      toggleBtn.addEventListener('click', function(e) {
        const isActive = filterGroup.classList.contains(openClass);
        const oldActive = !isActive && document.querySelector(`.${openClass}`);

        e.preventDefault();

        oldActive && oldActive.classList.remove(openClass);
        filterGroup.classList.toggle(openClass);
      });
    }
  }

  mobileToggler && mobileToggler.addEventListener('click', function(e) {
    e.preventDefault();

    document.querySelector('.filter').classList.toggle('filter--mobile-show');
  });

  form && form.addEventListener('change', function() {
    const data = new FormData(form);
    const filterParams = [];

    for (const name of data.keys()) {
      const values = data.getAll(name);
      let skipParam = false;

      for (const value of values) {
        skipParam = (value === null) || (value.length < 1);

        if(skipParam) {
          break;
        }
      }

      !skipParam && filterParams.push({name, values});
    }

    filterList(filterParams);
  });

  if(filterOptionsElements && filterTitleElements && filterOptionsContainer) {
    for (const filterTitle of filterTitleElements) {
      const filterTitleOptions = {
        root: document.querySelector('.people__filter-wrap.people__filter-wrap--sorted'),
        rootMargin: '0px 0px 0px 0px',
        threshold: 1.0
      }

      const observer = new IntersectionObserver((entries) => {
        if(entries[0].isIntersecting) {
          const letter = entries[0].target.getAttribute('data-letter');
          requestAnimationFrame(() => scrollToFilterOption(letter));
        }
      }, filterTitleOptions);

      observer.observe(filterTitle);
    }

    updateRect();
    window.addEventListener('resize', updateRect);
    window.addEventListener('load', updateRect);
  }
}

function filterList(filterParams) {
  const items = document.querySelectorAll('[data-filter-item]');

  for (const item of items) {
    let hidden = false;

    for (const group of filterParams) {
      const searchParam = item.getAttribute(`data-filter-${group.name}`);

      if(group.values.includes('all')) {
        hidden = false;
      } else if(searchParam) {
        hidden = !group.values.includes(searchParam);
      }

      if(hidden) {
        break;
      }
    }

    item.classList.toggle('hidden', hidden);

    !hidden && item.classList.add('shake');
    !hidden && setTimeout(() => item.classList.remove('shake'), 50);
  }

  checkForVisibility();
}


function updateRect() {
  const option = filterOptionsElements[0] && filterOptionsElements[1];
  const withSubScroll = filterOptionsContainer.clientHeight > (window.innerHeight - 130);
  const stepHeight = option.clientHeight;
  const stepOffset = parseInt(getComputedStyle(option).marginTop);
  const windowHeight = window.innerHeight;
  const skipElements = parseInt((windowHeight/(stepHeight+stepOffset))*0.4);

  filterParams = {
    withSubScroll,
    stepHeight,
    stepOffset,
    windowHeight,
    skipElements
  }
}

function scrollToFilterOption(letter) {
  const {withSubScroll, stepHeight, stepOffset, skipElements} = filterParams;

  if(!withSubScroll) {
    return;
  }

  let index = [...filterOptionsElements].findIndex((element) =>
    element.dataset.letter === letter
  ) - skipElements;

  index = index < 0 ? 0 : index;
  index = index > filterOptionsElements.length ?
    filterOptionsElements.length : index;

  const offset = 0 - index * (stepHeight + stepOffset);

  filterOptionsContainer.style.transform = `translateY(${offset}px)`;
}
