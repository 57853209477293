import cookie from 'cookie';

const cookiePane = document.querySelector('.cookie');
const cookieBtn = cookiePane && cookiePane.querySelector('.cookie__btn');
const cookieName = 'cookie_pane_clicked';

export default function() {
  if(cookieBtn) {
    const pageCookie = document.cookie || '';
    const clicked = (cookie.parse(pageCookie)[cookieName] === 'true');

    if(!clicked) {
      cookiePane.classList.remove('hidden');

      cookieBtn.addEventListener('click', (e) => {
        e.preventDefault();

        document.cookie = cookie.serialize(cookieName, 'true', {
          maxAge: 60 * 60 * 24 * 365,
          path: '/',
        });

        cookiePane.classList.add('hidden');
      })
    }
  }
}
