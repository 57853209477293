import ApplicationController from "@script/controllers/application_controller";

import ajax from '@script/utils/ajax';
import replace from '@script/utils/replace';

export default class PaginatorController extends ApplicationController {
  static values = {page: Number, pages: Number, url: String}

  connect(){
    super.connect();
  }

  next(){
    this.loadPage(this.pageValue+1);
  }

  prev(){
    this.loadPage(this.pageValue-1);
  }

  loadPage(page){
    ajax.get(this.urlValue + '?page=' + page)
      .then(response => response.json())
      .then((data)=>{
        replace(this.element, data.html);
      });

  }
}
