import { throttle } from "lodash";

const tickerTags = document.querySelectorAll('.ticker__row');
const tickerContainer = document.querySelector('.ticker');

const baseRowSpeed = 50;
const mouseSensitivity = 30;
let playStateTimer = null;
let lastMouse = {
  x: 0,
  y: 0
};
let isDesktopMode = false;
let playStateTimerDuration = 0;
let lastWindowWidth = 0;

export default function() {
  if(tickerTags && tickerTags.length) {
    isDesktopMode = window.innerWidth > 1179;
    playStateTimerDuration = isDesktopMode ? 200 : 70;

    window.addEventListener('load', () => updateTicker());

    window.addEventListener('resize', throttle(updateTicker, 500, {
      leading: false,
    }));

    window.addEventListener('scroll', throttle(updatePlayState, playStateTimerDuration), {
      leading: true,
      trailing: true,
      passive: true
    });

    if(isDesktopMode) {
      window.addEventListener('mousemove', throttle(updatePlayState, playStateTimerDuration), {
        leading: true,
        trailing: true,
        passive: true
      });
    } else {
      setTimeout(() => {
        tickerContainer.classList.remove('onscroll-animation');
        tickerContainer.removeAttribute('data-scroll-show');
      }, 100)
    }
  }
}

function updateTicker() {
  if(lastWindowWidth != window.innerWidth) {
    for (const tickerWrapper of tickerTags) {
      initTicker(tickerWrapper);
    }

    lastWindowWidth = window.innerWidth;
  }
}

function initTicker(wrapper) {
  wrapper.classList.remove('ticker__row--visible');

  setTimeout(() => {
    const holder = wrapper.querySelector('.ticker__item--holder');
    const main = wrapper.querySelector('.ticker__item--main');
    const round = wrapper.querySelector('.ticker__item--round');

    const holderWidth = holder.clientWidth;

    if(holderWidth) {
      const duration = Math.floor(baseRowSpeed/2+baseRowSpeed*Math.random());
      const holderInner = holder.innerHTML;
      let repeat = Math.floor(window.innerWidth*2/holderWidth);
      let result = '';

      holder.style.animationDuration = `${duration}s`;
      round.style.animationDuration = `${duration}s`;
      main.style.animationDuration = `${duration}s`;

      repeat = repeat < 1 ? 1 : repeat;
      repeat = repeat%2 > 0 ? repeat + 1 : repeat;

      for (let i = 0; i < repeat; i++) {
        result += holderInner;
      }

      main.innerHTML = result;
      round.innerHTML = result;
    }

    wrapper.classList.add('ticker__row--visible');
  }, 100);
}

function updatePlayState(e) {
  requestAnimationFrame(() => {
    if(e.type == 'mousemove') {
      const { x, y } = e;
      const shiftX = Math.abs(x-lastMouse.x);
      const shiftY = Math.abs(y-lastMouse.y);

      if(shiftX + shiftY < mouseSensitivity) {
        return;
      }

      lastMouse = { x, y };
    }

    tickerContainer.classList.add('ticker--play');

    clearTimeout(playStateTimer);

    playStateTimer = setTimeout(() => {
      tickerContainer.classList.remove('ticker--play');
    }, playStateTimerDuration);
  });
}
