import { throttle } from "lodash";

const onScrollElements = document.querySelectorAll('.onscroll-animation, .container, .content-block, .publications__item, .more-perfomances__item');
const zone = 50; //px

export default function () {

  // Айфоны последних моделей не справляются с этим:
  for (const item of onScrollElements) {
    if (!item.getAttribute('data-scroll-show')) {
      item.setAttribute('data-scroll-show', false);
    }
  }

  document.addEventListener('DOMContentLoaded', function () {
    setTimeout(document.body.classList.add('body--loaded'), 500);

    checkForVisibility();
    setTimeout(checkForVisibility, 600);

    window.addEventListener('scroll', throttle(checkForVisibility, 100), { passive: true });
    window.addEventListener('resize', checkForVisibility);
  });
}

function checkForVisibility() {
  const checkItems = document.querySelectorAll('[data-scroll-show="false"]');
  // const checkItems = [];
  for (const item of checkItems) {
    const { top } = item.getBoundingClientRect();

    if (top < window.innerHeight - zone) {
      item.setAttribute('data-scroll-show', true);
      // Новые айфоны не справляются со всеми этими таймаутами, когда в афише много-много строчек
      // но, отключение этого блока останавливает важную анимацию на первой страницен ("СОВРЕМЕННИК/СОЕДИНЕНИЕ" и пр.)
      // в качестве компромиса - отключение у всех, кроме тех самых буков:
      if (item.classList.contains("onscroll-animation")) {
        setTimeout(() => item.removeAttribute('data-scroll-show'), 500);
      }
      // setTimeout(() => item.removeAttribute('data-scroll-show'), 500);
    }
  }
}

export { checkForVisibility };
