const dateBtns = document.querySelectorAll('.ticket__date');
const datesWrap = document.querySelector('.ticket__dates');

export default function() {
  for (const dateBtn of dateBtns) {
    dateBtn.addEventListener('click', function(e) {
      const activeClass = 'ticket__date--active';
      const isActive = dateBtn.classList.contains(`${activeClass}`);
      const oldActiveBtn = document.querySelector(`.${activeClass}`);

      if(!isActive) {
        oldActiveBtn && oldActiveBtn.classList.remove(`${activeClass}`);
        dateBtn.classList.add(`${activeClass}`);
      } else if(window.innerWidth < 1024) {
        e.preventDefault();
        datesWrap.classList.toggle('ticket__dates--open');
      }
    });
  }
}
