import scrollTo, {offset} from '@script/utils/scroll-to';

const repertoireContainer = document.querySelector('.repertoire__container');
const classCard = 'more-perfomances__item';
const classCardHover = 'more-perfomances__item--hover';
const classLink = 'repertoire__link';
const classLinkHover = 'repertoire__link--hover';
const classScrollInner = 'repertoire__scroll-inner';
const classColumnCards = 'repertoire__column--cards';
const classScrollInnerHover = 'repertoire__scroll-inner--hover';
const classColumnCardsHover = 'repertoire__column--cards--hover';
const scrollInner = repertoireContainer && repertoireContainer.querySelector(`.${classScrollInner}`);
const cardsInner = repertoireContainer && repertoireContainer.querySelector(`.${classColumnCards}`);
const links = repertoireContainer && repertoireContainer.querySelectorAll(`.${classLink}[data-repertoire]`);
const cards = repertoireContainer && repertoireContainer.querySelectorAll(`.${classCard}[id]`);

const wheelSleepTime = 100;
const speedCards = 200;
const speedLinks = 200;
let lastResetTime = null;
let scrollTimeout = null;
let headerOffset = 0;

export default function() {
  if(repertoireContainer) {
    repertoireContainer.addEventListener('mouseleave', function() {
      cardsInner.classList.remove(classColumnCardsHover);
      scrollInner.classList.remove(classScrollInnerHover);
    }, {passive: true});

    scrollInner.addEventListener('mouseleave', function() {
      scrollTo(window.pageYOffset, 0);
      cardsInner.classList.remove(classColumnCardsHover);
      reset();
    }, {passive: true});

    cardsInner.addEventListener('mouseleave', function() {
      scrollTo(window.pageYOffset, 0, scrollInner);
      scrollInner.classList.remove(classScrollInnerHover);
      reset();
    }, {passive: true});

    window.addEventListener('wheel', reset, {passive: true});

    for (const link of links) {
      link.addEventListener('mouseenter', linkMouseEnter);
    }

    for (const card of cards) {
      card.addEventListener('mouseenter', cardMouseEnter, {passive: true});
    }

    window.addEventListener('load', updateHeaderOffset);
    window.addEventListener('resize', updateHeaderOffset);
  }
}

function linkMouseEnter(e, skipTimer) {
  const id = e.target.getAttribute('data-repertoire');
  const scrollTarget = document.querySelector(`.${classCard}[id="${id}"`);
  const hoveredCard = document.querySelector(`.${classCardHover}`);

  e.preventDefault();

  if(scrollTarget) {
    updateScrollTimeout(scrollTarget, speedCards, null, skipTimer);
    hoveredCard && hoveredCard.classList.remove(classCardHover);
    scrollTarget.classList.add(classCardHover);
    cardsInner.classList.add(classColumnCardsHover);
  }
}

function cardMouseEnter(e, skipTimer) {
  const id = e.target.getAttribute('id');
  const scrollTarget = document.querySelector(`.${classLink}[data-repertoire="${id}"`);
  const hoveredLink = document.querySelector(`.${classLinkHover}`);

  if(scrollTarget) {
    updateScrollTimeout(scrollTarget, speedLinks, scrollInner, skipTimer);
    hoveredLink && hoveredLink.classList.remove(classLinkHover);
    scrollTarget.classList.add(classLinkHover);
    scrollInner.classList.add(classScrollInnerHover);
  }
}

function updateScrollTimeout(scrollTarget, speedLinks, scrollInner, skipTimer) {
  const delay = wheelSleepTime - (Date.now() - lastResetTime);
  const targetElementPositon = offset(scrollTarget).top - headerOffset;

  clearTimeout(scrollTimeout);

  if(delay < 0 || skipTimer) {
    scrollTo(targetElementPositon, speedLinks, scrollInner);
    reset();
  } else {
    scrollTimeout = setTimeout(() => scrollTo(targetElementPositon, speedLinks, scrollInner), delay);
  }
}

function updateHeaderOffset() {
  const header = document.querySelector('.header__fixed-wrap');

  if(header) {
    headerOffset = header.clientHeight;
  }
}

function reset() {
  lastResetTime = new Date();
}
