import ApplicationController from "@script/controllers/application_controller";
import cookie from 'cookie';

export default class NotificationController extends ApplicationController {
  connect(){
    super.connect();

    if(!this.isClicked){
      this.element.modalController.open();
      this.listen('modal:close', () => this.close());
    }
  }

  close(){
    if(this.cookieName){
      document.cookie = cookie.serialize(this.cookieName, '1', {
        maxAge: 60 * 60 * 24 * 365,
        path: '/',
      });
    }
  }

  get cookieName() {
    const id = this.element.dataset.id

    return id ? `notification-${id}` : null;
  }

  get isClicked() {
    const cookies = cookie.parse(document.cookie || '');

    if(this.cookieName){
      return cookies[this.cookieName] === '1';
    }
  }
}
